import icnIdentity from 'services/icn_identity/icnIdentity';
import { matchPath } from 'react-router';
import { routeCodes } from 'router';
import { CLIENT_MANAGEMENT_ENTRY, CLIENT_MANAGEMENT_PAGE_ENTRY } from 'containers/client_management/constants';
import { SUBSCRIPTION_REVIEW_PAGE_ENTRY } from 'containers/subscription_review/constants';
import { REQUIRED_DOCUMENTS_PAGE_ENTRY } from 'modules/investments/shared/document_box/helpers/constants';
import { MANAGE_CLOSE_PAGE_ENTRY } from 'modules/manage_close/pages/manageClose/constants/constants';

export const currentPageName = (path) => {
  if (matchPath(path, { path: routeCodes.REVERTED_DOCUMENTS })) return 'remediation_comments';

  const clientManagementPageEntry = matchPath(path, { path: routeCodes.CLIENT_MANAGEMENT })?.params.pageEntry;
  if (clientManagementPageEntry) return clientManagementPageEntry;

  const subscriptionReviewPageEntry = path.match(new RegExp(SUBSCRIPTION_REVIEW_PAGE_ENTRY.id))?.at(0);
  if (subscriptionReviewPageEntry) return SUBSCRIPTION_REVIEW_PAGE_ENTRY.route;

  const requiredDocumentsPageEntry = path.match(new RegExp(REQUIRED_DOCUMENTS_PAGE_ENTRY))?.at(0);
  if (requiredDocumentsPageEntry) return requiredDocumentsPageEntry;

  const manageClosePageEntry = path.match(new RegExp(MANAGE_CLOSE_PAGE_ENTRY.id))?.at(0);
  if (manageClosePageEntry) return MANAGE_CLOSE_PAGE_ENTRY.route;

  return path.match(new RegExp(CLIENT_MANAGEMENT_PAGE_ENTRY))?.at(0) ?? CLIENT_MANAGEMENT_ENTRY.id;
};

export const currentPafId = (path) => {
  const pathArray = path.split('/');
  const pafIndex = pathArray.indexOf(currentPageName(path)) + 1;
  return pathArray[pafIndex];
};

export const isInitialInvestment = (investment) => Boolean(investment && !investment.initial_investment_id);
export const isAddonInvestment = (investment) => Boolean(investment && investment.initial_investment_id);
export const canEditFrozenSuppForm = (user, paf) => (
  icnIdentity.canEditFrozenProfiles(user, paf)
);

export const lockedApprovalStatuses = ['External Review', 'External Review 2', 'IR Review', 'Compliance Review'];
export const isLockedApprovalStatus = (investment) => lockedApprovalStatuses.includes(investment?.approval_status);

export const isUserNotAllowedViewSuppFormInvProfile = (user) => (
  icnIdentity.isRiaOrBranchManager(user) || icnIdentity.isIndividualInvestor(user)
);

export const canEditInvestorProfiles = (user) => (
  icnIdentity.canViewInvestorProfiles(user) ||
  icnIdentity.isRia(user) ||
  icnIdentity.isAdminOrIr(user) ||
  icnIdentity.canHaveInvestments(user)
);

export const missingUserAccount = ({ investment, user }) => (
  !investment || (investment.contact_card?.user_id === '' &&
  !icnIdentity.isExternalApprover(user))
);

export const hasNonRegisteredInterestedParties = (investment) => (
  investment.investor_profile?.interested_parties.some((ip) => !ip.registered)
);
