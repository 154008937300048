import { combineReducers } from 'redux';

import orderStatusDashboardReducer from './components/order_status_grid/reducer';
import apiProcessDashboardReducer from './components/api_process_grid/reducer';
import getAccountDashboardReducer from './components/get_account_grid/reducer';
import dltEventDashboardReducer from './components/dlt_event_grid/reducer';
import dashboardPageReducer from './containers/dashboard/reducer';

export const initialDashboardState = {
  currentViewFilters: {},
  defaultViewFilters: {},
  currentPage: 1,
  currentPageRows: 10,
  selectedItem: null,
  selectedItemLogs: null,
  selectedOldApiProcessesLogs: null,
  selectedUser: null,
  analytics: {},
  analyticsDaysBehind: 1,
  analyticsBridgeSelected: 'ubs-api',
  syncWebhookEnabled: true,
};

export default combineReducers({
  orderStatusDashboard: orderStatusDashboardReducer,
  apiProcessDashboard: apiProcessDashboardReducer,
  getAccountDashboard: getAccountDashboardReducer,
  dashboardPage: dashboardPageReducer,
  dltEventDashboard: dltEventDashboardReducer,
});
