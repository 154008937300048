import { initialPaginationState, initialSortState } from '../../services/metaService';

export const mainSnackbarStates = {
  mainSnackbar: {
    open: false,
    message: '',
    severity: undefined,
  },
};

export const breadcrumbStates = {
  breadcrumbs: [],
};

export const baseTemplatesStates = {
  baseTemplates: [],
};

export const baseTemplateStates = {
  baseTemplate: undefined,
};

export const templateStates = {
  templates: [],
  template: undefined,
  templatesDownloading: [],
  templateTags: [],
};

const pagination = initialPaginationState({ page: 0 });
const sort = initialSortState({ sort_field: 'integration_partner_templates.name' });
export const templatesPaginationStates = {
  templates_pagination: { ...pagination, ...sort },
};

export const integrationPartnerStates = {
  integrationPartners: [],
};

export const privateAccessFundStates = {
  privateAccessFunds: [],
};

export const providerStates = {
  providers: [],
};

export const whiteLabelPartnerStates = {
  white_label_partners: [],
};

export const generalLedgerAccountIdStates = {
  generalLedgerAccountIds: [],
};

export const firmStates = {
  firms: [],
};

export const filterOptionsStates = {
  filterOptions: {},
};

export default {
  ...mainSnackbarStates,
  ...breadcrumbStates,
  ...baseTemplatesStates,
  ...baseTemplateStates,
  ...templateStates,
  ...templatesPaginationStates,
  ...integrationPartnerStates,
  ...privateAccessFundStates,
  ...providerStates,
  ...whiteLabelPartnerStates,
  ...generalLedgerAccountIdStates,
  ...firmStates,
  ...filterOptionsStates,
};
