import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useSnackbar } from '@icapitalnetwork/supernova-hooks';
import { NotificationMessage } from 'components/shared/notifications/NotificationMessage';
// TODO: When migration to V2 Notifications, please test the Async agreement notifications since the multiline option
//  added here is used on them. According to the V2 documentation, the multiline and description option will be
//  available by default. The notification should look identical to the current one.
export function useDefaultNotifications() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const enqueueMessage = useCallback((message, variant = 'default', options = {}) => {
        const id = enqueueSnackbar(options.multiline ? // TODO: When migrating to V2 notifications, remove this multiline validation and component, return only the message.
            _jsx(NotificationMessage, { title: message, description: options.description, actionButtonLabel: options.actionButtonLabel, onActionClick: () => { if (options.onActionClick)
                    options.onActionClick(() => closeSnackbar(id)); } })
            :
                message, Object.assign(Object.assign({ variant, anchorOrigin: { vertical: 'top', horizontal: 'right' } }, options), { onCloseAction: options.hideCloseButton ? undefined : () => closeSnackbar(id), actionButtonLabel: options.multiline ? undefined : options.actionButtonLabel, onActionClick: options.multiline ? undefined : () => {
                if (typeof options.onActionClick === 'function') {
                    options.onActionClick(() => closeSnackbar(id));
                }
            } }));
    }, [closeSnackbar, enqueueSnackbar]);
    const enqueueSnackbarDefault = useCallback((message, options = {}) => enqueueMessage(message, 'default', options), [enqueueMessage]);
    const enqueueSnackbarError = useCallback((message, options = {}) => enqueueMessage(message, 'error', options), [enqueueMessage]);
    const enqueueSnackbarSuccess = useCallback((message, options = {}) => enqueueMessage(message, 'success', options), [enqueueMessage]);
    const enqueueSnackbarInfo = useCallback((message, options = {}) => enqueueMessage(message, 'info', options), [enqueueMessage]);
    const enqueueSnackbarWarning = useCallback((message, options = {}) => enqueueMessage(message, 'warning', options), [enqueueMessage]);
    return {
        enqueueSnackbar: enqueueMessage,
        enqueueSnackbarDefault,
        enqueueSnackbarError,
        enqueueSnackbarSuccess,
        enqueueSnackbarInfo,
        enqueueSnackbarWarning,
    };
}
