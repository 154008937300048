export const INVESTMENTS_TAB = Object.freeze({
    labelKey: 'common:investments',
    id: 'investments',
});
export const ACCEPTED_TAB = Object.freeze({
    labelKey: 'common:accepted',
    id: 'accepted',
});
export const TRANSFERS_TAB = Object.freeze({
    labelKey: 'common:transfers',
    id: 'transfers',
});
export const REDEMPTIONS_TAB = Object.freeze({
    labelKey: 'common:redemptions',
    id: 'redemptions',
});
export const TABS = Object.freeze([INVESTMENTS_TAB, ACCEPTED_TAB, TRANSFERS_TAB, REDEMPTIONS_TAB]);
