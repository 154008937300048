import { getIsUnifiedHomepageEarlyAccessEnabled } from '../../components/UnifiedHomepagePromo/utils';

export const isHomepageMFEEnabled = (icnReactBootstrap) => {
  const isMultiAssetEnabled =
    icnReactBootstrap?.user?.permissions?.includes?.('see_unified_homepage_multi_asset') &&
    icnReactBootstrap?.white_label_partner.enable_multi_asset_mfe;
  const isUnifiedHomepageEnabled = icnReactBootstrap?.user?.permissions?.includes?.('see_unified_homepage');
  const isPromoOptionEnabled = icnReactBootstrap?.user?.permissions?.includes?.('see_unified_homepage_promo');
  const hasHomepagePermissions = isMultiAssetEnabled || isUnifiedHomepageEnabled;

  if (isPromoOptionEnabled && hasHomepagePermissions) {
    return getIsUnifiedHomepageEarlyAccessEnabled();
  }
  return hasHomepagePermissions;
};
