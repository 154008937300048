import { CLIENT_MANAGEMENT_ENTRY, INVESTMENT_MANAGEMENT_ENTRY } from 'containers/client_management/constants';
import { stringify } from 'query-string';
import { generatePath } from 'react-router';
import { routeCodes } from 'router';
import { prependPartnerProxyAPIPrefix } from 'services/axios/setBaseUrl';

/**
 * About fullPath: when using react-router-dom to change url,
 * the partner prefix, if defined, is automatically inserted.
 * false -> url to be used with react-router-dom (history.push, Link, etc)
 * true -> url to be used without react-router-dom (location.assign, <a>, etc)
 */
const createUrl = (fullPath, url, queryString) => {
  const finalUrl = queryString ? url.concat(`?${queryString}`) : url;
  return fullPath ? prependPartnerProxyAPIPrefix(finalUrl) : finalUrl;
};

export const supplementalForm = ({
  investment, paf, extraParams = {}, fullPath = false, isRedemption = false,
}) => {
  const url = generatePath(routeCodes[isRedemption ? 'REDEMPTION_SUPPLEMENTAL_FORM' : 'SUPPLEMENTAL_FORM'], { id: investment.id });
  const queryString = stringify({
    paf_id: paf.id,
    returnUrl: window.location.pathname,
    ...extraParams,
  });
  return createUrl(fullPath, url, queryString);
};

export const manageFundingStatus = ({ paf, fullPath = false }) => {
  const url = generatePath(routeCodes.MANAGE_FUNDING_STATUS, { id: paf.id });
  return createUrl(fullPath, url);
};

export const investorProfile = ({
  investment,
  step = 1,
  extraParams = {},
  fullPath = false,
}) => {
  const route = `${routeCodes.INV_PROF}/:profileId/:stepPath?/:step?`;
  const url = generatePath(route, { profileId: investment.investor_profile_id, stepPath: 'step', step });
  const queryString = stringify({
    private_access_fund_id: investment.private_access_fund_id,
    returnUrl: window.location.pathname,
    ...extraParams,
  }, { arrayFormat: 'bracket' });
  return createUrl(fullPath, url, queryString);
};

export const remediationPage = ({
  investment, from, commitmentAmount, returnUrl, fullPath = false,
}) => {
  const route = `${routeCodes.REVERTED_DOCUMENTS}`;
  const url = generatePath(route, { investmentId: investment.id });
  const queryString = stringify({ from, commitmentAmount, returnUrl });
  return createUrl(fullPath, url, queryString);
};

export const subscriptionReview = ({ pafId, fullPath = false, queryString = null }) => {
  const route = `${routeCodes.SUBSCRIPTION_REVIEW}/:pafId?`;
  const url = generatePath(route, { pafId });
  return createUrl(fullPath, url, queryString);
};

export const preApprovalQuestionnaire = ({
  successUrl,
  amount,
  backUrl,
  profileId,
  createType,
  whiteLabelPartnerId,
  pafId,
  investmentId,
  acceptedBlocks,
  fullPath = false,
}) => {
  const url = generatePath(routeCodes.PRE_APPROVAL_QUESTIONNAIRE);
  const queryString = stringify({
    amount,
    wlp_id: whiteLabelPartnerId,
    paf_id: pafId,
    investment_id: investmentId,
    create_type: createType,
    investor_profile_id: profileId,
    accepted_blocks: acceptedBlocks,
    back_url: backUrl,
    success_url: successUrl || backUrl,
  });
  return createUrl(fullPath, url, queryString);
};

export const agreementSigning = ({
  params = {}, fullPath = false,
}) => {
  const queryString = stringify({ ...params });
  return createUrl(fullPath, routeCodes.AGREEMENT_SIGNING, queryString);
};

export const esignHub = ({ params = {}, fullPath = false }) => {
  const queryString = stringify({ ...params });
  return createUrl(fullPath, routeCodes.ESIGN_HUB, queryString);
};

export const clientManagement = ({ pafId, advisorId, openGrid, fullPath = false, queryParams = {} }) => {
  const queryString = stringify({ openGrid, ...queryParams });
  const params = { pageEntry: CLIENT_MANAGEMENT_ENTRY.id };
  if (pafId) params.pafId = pafId;
  if (advisorId) params.advisorId = advisorId;
  const url = generatePath(routeCodes.CLIENT_MANAGEMENT, params);
  return createUrl(fullPath, url, queryString);
};

export const investmentManagement = ({ pafId, openGrid = undefined, fullPath = false }) => {
  const params = { pageEntry: INVESTMENT_MANAGEMENT_ENTRY.id };
  const queryString = stringify({ openGrid });
  if (pafId) params.pafId = pafId;
  const url = generatePath(routeCodes.CLIENT_MANAGEMENT, params);
  return createUrl(fullPath, url, queryString);
};

export const requiredDocuments = ({ investment, returnUrl, fullPath = false }) => {
  const queryString = stringify({
    investment_id: investment.id,
    investor_profile_id: investment.investor_profile_id,
    returnUrl,
  });
  const route = `${routeCodes.REQUIRED_DOCUMENTS}?${queryString}`;

  const url = generatePath(route);
  return createUrl(fullPath, url);
};

export const viewDocuments = ({ investmentId, fullPath = false }) => {
  const params = {
    investmentId,
  };

  const url = generatePath(routeCodes.VIEW_DOCUMENTS, params);
  return createUrl(fullPath, url);
};

export const closeSchedule = ({ pafId, fullPath = false }) => {
  const params = {
    pafId,
  };

  const url = generatePath(routeCodes.CLOSE_SCHEDULE, params);
  return createUrl(fullPath, url);
};

export const manageClose = ({ pafId, fullPath = false }) => {
  const params = {
    pafId,
  };

  const url = generatePath(routeCodes.MANAGE_CLOSE, params);
  return createUrl(fullPath, url);
};

export const exportHistory = ({
  pafId, page = 1, range = 30, fullPath = false,
}) => {
  const queryString = stringify({
    pafId,
    page,
    range,
  });

  const url = generatePath(routeCodes.CM_EXPORT_HISTORY);
  return createUrl(fullPath, url, queryString);
};

export const sendGPCountersignature = ({ pafId, fullPath = false }) => {
  const url = generatePath(routeCodes.SEND_GP_CERTIFICATE, { pafId });

  return createUrl(fullPath, url);
};

export const subscriptionDashboard = ({ fullPath = false, queryString = null } = {}) => {
  const url = generatePath(routeCodes.SUBSCRIPTIONS_DASHBOARD);

  return createUrl(fullPath, url, queryString);
};

export const investorSearch = ({ cardId, fullPath = false, queryString = null } = {}) => {
  const url = generatePath(routeCodes.INVESTOR_SEARCH, { cardId });

  return createUrl(fullPath, url, queryString);
};

export const investorSearchDetail = ({ contactCardId, fundId, fullPath = false, queryString = null } = {}) => {
  const url = generatePath(routeCodes.INVESTOR_SEARCH_DETAIL, { contactCardId, fundId });

  return createUrl(fullPath, url, queryString);
};

export const investorSearchEditContactDetails = ({ contactCardId, fundId, investmentId, fullPath = false } = {}) => {
  const url = generatePath(routeCodes.INVESTOR_SEARCH_EDIT_CONTACT_DETAILS);
  const queryString = stringify({
    contactCardId,
    fundId,
    investmentId,
  });
  return createUrl(fullPath, url, queryString);
};
