import filterReducer from './filterReducer';
import firmsReducer from './firmsReducer';
import fundsReducer from './fundsReducer';
import wlpsReducer from './wlpsReducer';
import modalReducer from './modalReducer';

export default {
  filterReducer,
  firmsReducer,
  fundsReducer,
  wlpsReducer,
  modalReducer,
};
