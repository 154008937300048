const API_DASHBOARD_ACTIONS = {
  SET_SELECTED_ORDER_STATUS: 'API_DASHBOARD_SET_SELECTED_ORDER_STATUS',
  GET_ORDER_STATUS_USER: 'API_DASHBOARD_GET_ORDER_STATUS_USER',
  GET_ORDER_STATUS_LOGS: 'API_DASHBOARD_GET_ORDER_STATUS_LOGS',
  GET_OLD_API_PROCESSES_LOGS: 'API_DASHBOARD_GET_OLD_API_PROCESSES_LOGS',
  SET_CURRENT_VIEW_FILTERS: 'API_DASHBOARD_SET_CURRENT_VIEW_FILTERS',
  SET_DEFAULT_VIEW_FILTERS: 'API_DASHBOARD_SET_DEFAULT_VIEW_FILTERS',
  SET_CURRENT_PAGE: 'API_DASHBOARD_SET_CURRENT_PAGE',
  SET_CURRENT_PAGE_ROWS: 'API_DASHBOARD_SET_CURRENT_PAGE_ROWS',
  SET_SELECTED_API_PROCESS: 'API_DASHBOARD_SET_SELECTED_API_PROCESS',
  GET_API_PROCESS_LOGS: 'API_DASHBOARD_GET_API_PROCESS_LOGS',
  SET_API_PROCESS_CURRENT_VIEW_FILTERS:
    'API_DASHBOARD_SET_API_PROCESS_CURRENT_VIEW_FILTERS',
  SET_API_PROCESS_DEFAULT_VIEW_FILTERS:
    'API_DASHBOARD_SET_API_PROCESS_DEFAULT_VIEW_FILTERS',
  SET_API_PROCESS_CURRENT_PAGE: 'API_DASHBOARD_SET_API_PROCESS_CURRENT_PAGE',
  SET_API_PROCESS_CURRENT_PAGE_ROWS:
    'API_DASHBOARD_SET_API_PROCESS_CURRENT_PAGE_ROWS',
  SET_SELECTED_GET_ACCOUNT: 'API_DASHBOARD_SET_SELECTED_GET_ACCOUNT',
  GET_GET_ACCOUNT_LOGS: 'API_DASHBOARD_GET_GET_ACCOUNT_LOGS',
  SET_GET_ACCOUNT_CURRENT_VIEW_FILTERS:
    'API_DASHBOARD_SET_GET_ACCOUNT_CURRENT_VIEW_FILTERS',
  SET_GET_ACCOUNT_DEFAULT_VIEW_FILTERS:
    'API_DASHBOARD_SET_GET_ACCOUNT_DEFAULT_VIEW_FILTERS',
  SET_GET_ACCOUNT_CURRENT_PAGE: 'API_DASHBOARD_SET_GET_ACCOUNT_CURRENT_PAGE',
  SET_GET_ACCOUNT_CURRENT_PAGE_ROWS:
    'API_DASHBOARD_SET_GET_ACCOUNT_CURRENT_PAGE_ROWS',
  SET_TAB: 'API_DASHBOARD_SET_TAB',
  GET_API_PROCESS_BRIDGE_IDS: 'GET_API_PROCESS_BRIDGE_IDS',
  SET_ORDER_STATUS_ANALYTICS: 'SET_ORDER_STATUS_ANALYTICS',
  SET_ORDER_STATUS_ANALYTICS_DAYS_BEHIND:
    'SET_ORDER_STATUS_ANALYTICS_DAYS_BEHIND',
  SET_API_PROCESS_ANALYTICS: 'SET_API_PROCESS_ANALYTICS',
  SET_API_PROCESS_ANALYTICS_DAYS_BEHIND:
    'SET_API_PROCESS_ANALYTICS_DAYS_BEHIND',
  SET_API_PROCESS_ANALYTICS_BRIDGE_SELECTED:
    'SET_API_PROCESS_ANALYTICS_BRIDGE_SELECTED',
  SET_GET_ACCOUNT_ANALYTICS: 'SET_GET_ACCOUNT_ANALYTICS',
  SET_GET_ACCOUNT_ANALYTICS_DAYS_BEHIND:
    'SET_GET_ACCOUNT_ANALYTICS_DAYS_BEHIND',
  SET_GET_ACCOUNT_ANALYTICS_BRIDGE_SELECTED:
    'SET_GET_ACCOUNT_ANALYTICS_BRIDGE_SELECTED',
  SET_SELECTED_DLT_EVENT: 'SET_SELECTED_DLT_EVENT',
  SET_DLT_EVENT_DASHBOARD: 'SET_DLT_EVENT_DASHBOARD',
  SET_DLT_EVENT_CURRENT_VIEW_FILTERS: 'SET_DLT_EVENT_CURRENT_VIEW_FILTERS',
  SET_DLT_EVENT_DEFAULT_VIEW_FILTERS: 'SET_DLT_EVENT_DEFAULT_VIEW_FILTERS',
  SET_DLT_EVENT_CURRENT_PAGE_ROWS: 'SET_DLT_EVENT_CURRENT_PAGE_ROWS',
  SET_DLT_EVENT_CURRENT_PAGE: 'SET_DLT_EVENT_CURRENT_PAGE',
  SET_DLT_SYNC_WEBHOOKS_ENABLED: 'SET_DLT_SYNC_WEBHOOKS_ENABLED'
};

export default API_DASHBOARD_ACTIONS;
