export const ALL = 'all';
export const LINKED = 'linked';
export const UNLINKED = 'unlinked';
export const NEW = 'new';
export const CURRENT = 'current';
export const CONFIRM = 'confirm';
export const LINK_ACCOUNT = 'linkAccount';
export const UNIFIED_PASSWORD = 'unifiedPassword';
export const CHANGE_PASSWORD = 'changePassword';
export const WELCOME = 'welcome';
export const SOURCE = 'source';
export const DESTINATION = 'destination';

export const NAV_BLACKLIST = [
  '/icn_react/static/onboarding',
  '/icn_react/static/select_accounts',
  '/icn_react/static/link_accounts',
];
