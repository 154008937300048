import { SE_LOAD_WLPS_SUCCESS } from 'actions/actionsConstants';

import update from 'immutability-helper';

import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case SE_LOAD_WLPS_SUCCESS:
      return update(state, { wlps: { $set: action.payload } });
    default:
      return state;
  }
};
