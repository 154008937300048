import React, { useState, useEffect } from 'react';
import { bool, shape } from 'prop-types';
import { connect } from 'react-redux';
import PassportSnackbar from 'modules/passport/components/PassportSnackbar';
import AccessAgreementsSnackbar from 'components/access_agreements/AccessAgreementsSnackbar';
import AccessAgreements from 'components/access_agreements/AccessAgreements';
import {
  isAgreementsModalOpen as isAgreementsModalOpenSelector,
  getAgreementsRequestFailed,
} from 'reducers/access_agreements_reducers/selectors';
import icnReactBootstrapShape from 'prop_types/shared/icn_react_bootstrap';
import { getUser } from '../../../../selectors/icnBootstrapSelectors';
import MarketingBanner from '../../components/home/MarketingBanner/MarketingBanner';
import CoolOffNotice from '../../../../containers/cool_off_notice/CoolOffNotice';

import styles from './index.module.scss';

const BannerHandler = ({
  user,
  agreementsRequestFailed,
  isAgreementsModalOpen,
  icnReactBootstrap: { white_label_partner: { enable_sitenav_mfe: isSiteNavMFEEnabled } = {} } = {},
}) => {
  const [isPassportLinked, setIsPassportLinked] = useState(false);

  useEffect(() => {
    const prevPage = localStorage.getItem('prevPage');
    if (prevPage === 'welcome') {
      setIsPassportLinked(true);
      localStorage.setItem('isPassportLinked', 'true');
      localStorage.removeItem('prevPage');
    }
  }, []);

  useEffect(() => {
    const linked = localStorage.getItem('isPassportLinked') === 'true';
    if (linked) {
      setIsPassportLinked(true);
      setTimeout(() => {
        localStorage.removeItem('isPassportLinked');
        setIsPassportLinked(false);
      }, 10000);
    }
  }, []);

  const viewPassportSnackbar = !user.is_imposter && isPassportLinked && user.canSeePassport && !isSiteNavMFEEnabled;

  return (
    <>
      <CoolOffNotice />
      <MarketingBanner className={styles.marketingBanner} />
      {viewPassportSnackbar && <PassportSnackbar />}
      {agreementsRequestFailed && <AccessAgreementsSnackbar />}
      {isAgreementsModalOpen && <AccessAgreements />}
    </>
  );
};

BannerHandler.propTypes = {
  icnReactBootstrap: icnReactBootstrapShape,
  user: shape({
    is_imposter: bool,
    canSeePassport: bool,
  }),
  agreementsRequestFailed: bool,
  isAgreementsModalOpen: bool,
};

BannerHandler.defaultProps = {
  icnReactBootstrap: undefined,

  user: { is_imposter: false, canSeePassport: false },
  agreementsRequestFailed: false,
  isAgreementsModalOpen: false,
};

const mapStateToProps = (state) => {
  return {
    user: getUser(state),
    icnReactBootstrap: state.icnReactBootstrap.icn_react_bootstrap,
    agreementsRequestFailed: getAgreementsRequestFailed(state),
    isAgreementsModalOpen: isAgreementsModalOpenSelector(state),
  };
};

export default connect(mapStateToProps)(BannerHandler);
