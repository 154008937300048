import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { currentPageName } from 'services/client_management/cell_renderers/helpers';
import { toReactUrl } from 'services/react_url/investments';
import { SIGNATURE_VENDORS } from 'modules/aml/constants';
import { routeCodes } from 'router';
import { useCurrentUserPermissions } from '../current_user';

export const useRedirectToDocument = (paf, signMethod, preparerBeingUsed, investmentId, openGrid) => {
  const history = useHistory();
  const { hasPermission } = useCurrentUserPermissions();
  const canAccessEsignHub = hasPermission('canAccessEsignHub');

  return (fileUrl, requiredDocumentId, signatureVendor, newSignMethod = signMethod) => {
    if (signatureVendor === SIGNATURE_VENDORS.ECHOSIGN || !requiredDocumentId) {
      window.open(fileUrl);
    } else {
      const url = createAgreementSigningUrl({
        pathname: window.location.pathname,
        signMethod: newSignMethod,
        pafId: paf.id,
        documentId: requiredDocumentId,
        preparerBeingUsed,
        canAccessEsignHub,
        investmentId,
        openGrid,
        history,
      });

      redirectUserToAgreementPage(url, history, canAccessEsignHub, requiredDocumentId);
    }
  };
};

export function createAgreementSigningUrl({
  pathname,
  history,
  documentId,
  investmentId,
  investorProfileId,
  pafId,
  signMethod,
  preparerBeingUsed,
  openGrid,
  canAccessEsignHub,
}) {
  if (canAccessEsignHub) {
    return routeCodes.ESIGN_HUB;
  } else {
    const from = currentPageName(pathname);
    const { from: ffrom } = queryString.parse(history.location.search);

    const params = {
      required_document_id: documentId,
      paf_id: pafId,
      from,
      ffrom,
      manual_agreement: signMethod === 'manual',
      preparation: preparerBeingUsed,
      openGrid,
    };

    if (from === 'remediation_comments') {
      params.investment_id = investmentId;
    }

    if (from === 'required_documents') {
      params.investment_id = investmentId;
      params.investor_profile_id = investorProfileId;
    }

    return toReactUrl.agreementSigning({ params });
  }
}

export function redirectUserToAgreementPage(url, history, canAccessEsignHub, documentId) {
  if (canAccessEsignHub) {
    history.push(
      {
        pathname: url
      },
      {
        required_document_id: documentId,
        background: history.location,
      }
    );
  } else {
    history.push(url);
  }
}