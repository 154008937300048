import { actionFactory } from 'services/actions';

import { getRequest, postRequest, patchRequest, deleteRequest } from 'actions/utils';

import {
  SE_LOAD_EXCLUSIONS_SUCCESS,
  SE_REMOVE_EXCLUSION,
  SE_LOAD_RIAS_FIRMS_SUCCESS,
  SE_LOAD_EXCLUSIONS_COUNT,
  SE_LOAD_FUNDS_SUCCESS,
  SE_SET_RESULTS_PER_PAGE,
  SE_SET_CURRENT_PAGE,
  SE_SET_SORTING,
  SE_SET_ACTIVE_MODAL,
  SE_SET_SELECTED_ROW,
  SE_LOAD_WLPS_SUCCESS,
} from '../actionsConstants';

const seLoadExclusionsSuccess = actionFactory(SE_LOAD_EXCLUSIONS_SUCCESS);

const seLoadExclusionsCount = actionFactory(SE_LOAD_EXCLUSIONS_COUNT);

const seLoadRiasFirmsSuccess = actionFactory(SE_LOAD_RIAS_FIRMS_SUCCESS);

const seLoadFundsSuccess = actionFactory(SE_LOAD_FUNDS_SUCCESS);

const seLoadWLPSuccess = actionFactory(SE_LOAD_WLPS_SUCCESS);

const seSetActiveModal = actionFactory(SE_SET_ACTIVE_MODAL);

const seSetSelectedRow = actionFactory(SE_SET_SELECTED_ROW);

function onSubdocTypeExclusionsSuccess(data) {
  return (dispatch) => {
    dispatch(seLoadExclusionsSuccess(data.sub_doc_type_exclusions));
    dispatch(seLoadExclusionsCount(data.sub_doc_type_exclusions_count));
  };
}

function seLoadExclusions() {
  return (dispatch, getState) => {
    const state = getState().subDocExclusions;
    return dispatch(
      getRequest({
        url: '/sub_doc_type_exclusions',
        params: {
          page: state.current_page,
          per_page: state.results_per_page,
          sorting_column: state.sorting_column,
          sorting_order: state.sorting_order,
        },
        onSuccess: onSubdocTypeExclusionsSuccess,
      })
    );
  };
}

function seUpdateExclusion(payload) {
  return patchRequest({
    url: `/sub_doc_type_exclusions/${payload.id}`,
    params: {
      id: payload.id,
      firm_id: payload.firmId,
      fund_id: payload.fundId,
      white_label_partner_id: payload.wlpId,
      sub_doc_type_id: payload.exclusionTypeId,
    },
    onSuccess: seLoadExclusions,
    successMessage: { message: 'Exclusion Edited' },
    errorMessage: { title: 'Error', message: 'An error Occurred Updating Exclusion' },
  });
}

function seCreateExclusion(payload) {
  return postRequest({
    url: '/sub_doc_type_exclusions',
    params: {
      firm_id: payload?.riaFirm?.id,
      fund_id: payload?.fund?.id,
      white_label_partner_id: payload?.wlp?.id,
      sub_doc_type_id: payload.selectedExcludeOption,
    },
    onSuccess: seLoadExclusions,
    successMessage: { message: 'Exclusion Created' },
    errorMessage: { title: 'Error', message: 'Exclusion already exists' },
  });
}

function seRemoveExclusionSuccess() {
  return (dispatch) => {
    dispatch({
      type: SE_REMOVE_EXCLUSION,
    });
    return dispatch(seLoadExclusions());
  };
}

function seRemoveExclusion(exclusion) {
  return deleteRequest({
    url: `/sub_doc_type_exclusions/${exclusion.id}`,
    onSuccess: seRemoveExclusionSuccess,
    infoMessage: { message: 'Exclusion Removed' },
  });
}

function seLoadRiasFirms() {
  return (dispatch) => {
    return dispatch(
      getRequest({
        url: 'firms/advisors_firms',
        onSuccess: seLoadRiasFirmsSuccess,
      })
    );
  };
}

function seLoadFunds(query = null) {
  return getRequest({
    url: 'funds/autocomplete',
    params: { query },
    onSuccess: seLoadFundsSuccess,
  });
}

function seLoadWLPs() {
  return (dispatch) => {
    return dispatch(
      getRequest({
        url: 'white_label_partners',
        onSuccess: seLoadWLPSuccess,
      })
    );
  };
}

function seSetResultsPerPage(payload) {
  return (dispatch) => {
    dispatch({
      type: SE_SET_RESULTS_PER_PAGE,
      payload,
    });
    return dispatch(seLoadExclusions());
  };
}

function seSetCurrentPage(payload) {
  return (dispatch) => {
    dispatch({
      type: SE_SET_CURRENT_PAGE,
      payload,
    });
    return dispatch(seLoadExclusions());
  };
}

function seSetSorting(sortingColumn, sortingOrder) {
  return (dispatch) => {
    dispatch({
      type: SE_SET_SORTING,
      payload: { sortingColumn, sortingOrder },
    });
    return dispatch(seLoadExclusions());
  };
}

export default {
  seLoadExclusions,
  seUpdateExclusion,
  seCreateExclusion,
  seRemoveExclusion,
  seLoadRiasFirms,
  seLoadFunds,
  seSetResultsPerPage,
  seSetCurrentPage,
  seSetSorting,
  seSetActiveModal,
  seSetSelectedRow,
  seLoadWLPs,
};
